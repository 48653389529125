export const IssueInfoData = [
    {id:0, 
      slug:'depression', 
      h1:'Depression / MDD', 
      number: '16', 
      location: 'people in Singapore',
      img: '1-in-16',
      help: 'counselling',
      body:'Major depressive disorder (MDD) is characterised by a depressed mood: a profound feeling of sadness, emptiness, worthlessness and hopelessness. Associated with this is a range of other symptoms such as loss of interest in activities, loss of pleasure in almost all activities, sleep disturbances (either not being able to sleep well or sleeping too much), and loss of appetite with consequent loss of weight, although in atypical cases, there might be overeating. Significant impairment in functioning is brought on by difficulty in concentrating, loss of energy, tiredness and listlessness. The depressed person may have suicidal thoughts or intentions which might lead to suicide attempts or even actual suicide.',
      sourceurl: 'https://www.imh.com.sg/research/',
      source: 'IMH SMHS 2016'
    },
    {id:1, 
      slug:'bipolar', 
      h1:'Bipolar', 
      number: '62', 
      location: 'people in Singapore',
      img: '1-in-62',
      help: 'counselling',
      body:'This disorder is characterised by depressive episodes (as described in MDD) and mania. Mania is characterised by abnormally elevated, expansive, or irritable mood. Together with this, there may be an inflated sense of self-esteem or even grandiosity, decreased need for sleep, talkativeness, agitation, a tendency to engage in activities which while pleasurable, would have painful consequences like spending sprees without the money to pay for them, increased sexual activity, reckless driving, as well as rash business and personal decisions. This state is often severe enough to seriously affect the person’s life.',
      sourceurl: 'https://www.imh.com.sg/research/',
      source: 'IMH SMHS 2016'},
    {id:2, 
      slug:'anxiety', 
      h1:'Anxiety / GAD', 
      number: '62',
      location: 'people in Singapore',
      img: '1-in-62',
      help: 'counselling',
      body:"The essential feature of generalised anxiety disorder (GAD) is a general feeling of excessive anxiety and worry that is difficult to control. These feelings are not related to any specific event or object but may be about a number of events and activities. They are often accompanied by other symptoms like restlessness, fatigue, irritability, and disturbed sleep. GAD can be chronic and recurrent in nature. It can impair family life and reduce social adjustment and functioning. GAD typically develops over a period of time and may not be noticeable until it is significant enough to cause problems with functioning.",
      sourceurl: 'https://www.imh.com.sg/research/',
      source: 'IMH SMHS 2016'},
    {id:3, 
      slug:'ocd', 
      h1:'Obessive Compulsive Disorder', 
      number: '28', 
      location: 'people in Singapore',
      img: '1-in-28',
      help: 'counselling',
      body:'Obsessive compulsive disorder (OCD) is characterised by the occurrence of obsessions, compulsive rituals or, most commonly, both recurrent and persistent thoughts, impulses, or images that are experienced as intrusive and cause great anxiety. They are not simply excessive worries about real life issues; the affected individual attempts to ignore, suppress, or neutralise them with other thoughts or actions and recognises that these thoughts are a product of his or her mind. Examples of obsessions include unwanted thoughts or images of harming loved ones, persistent doubts that one has not locked doors or switched off electrical appliances, and intrusive thoughts of being contaminated. Compulsions are repetitive behaviours (e.g. repetitive hand washing or checking) or mental acts (e.g. repetitive praying, counting, or thinking good thoughts to undo or replace bad thoughts) that the affected individual feels compelled to do in response to an obsession or according to rigid rules (e.g. checking that a light switch is turned off by switching it on and off exactly ten times).',
      sourceurl: 'https://www.imh.com.sg/research/',
      source: 'IMH SMHS 2016'},
    {id:4, 
      slug:'alcohol-abuse', 
      h1:'Alcohol Abuse', 
      number: '28', 
      location: 'people in Singapore',
      img: '1-in-28',
      help: 'specialists',
      body:'Alcohol abuse is diagnosed when one or more of the following occurs: a) Recurrent alcohol use resulting in failure to fulfill major role obligations at work, school, or home (e.g. repeated absences or poor work performance related to alcohol use; alcohol-related absences, suspensions or expulsions from school; or neglect of family or household) b) Recurrent use in situations in which it is physically hazardous (e.g. driving a car while under the influence of alcohol) c) Having alcohol-related legal problems (e.g. arrested for alcohol-related disorderly conduct). d) Continuing to use alcohol despite having persistent or recurrent social or interpersonal problems caused or worsened by the effects of alcohol (e.g. arguments with wife over consequences of being drunk or fights)',
      sourceurl: 'https://www.imh.com.sg/research/',
      source: 'IMH SMHS 2016'},
    {id:5, 
      slug:'did', 
      h1:'Dissociative Identity Disorder', 
      number: '67',
      location: 'people across the world',
      img: '1-in-67', 
      help: 'counselling',
      body:"Dissociative identity disorder (DID), previously known as multiple personality disorder (MPD), is a mental disorder characterized by the maintenance of at least two distinct and relatively enduring personality states. The disorder is accompanied by memory gaps beyond what would be explained by ordinary forgetfulness. The personality states alternately show in a person's behavior, however presentations of the disorder vary.",
      sourceurl: 'https://en.wikipedia.org/wiki/Dissociative_identity_disorder',
      source: 'Wikipedia'},
    {id:6, 
      slug:'general', 
      h1:'Multiple issues', 
      number: '100', 
      location: 'people in Singapore',
      img: '1-in-100',
      help: 'counselling',
      },
    {id:7, 
      slug:'general', 
      h1:'Mental health issues', 
      number: '7', 
      location: 'people in Singapore',
      img: '1-in-7',
      help: 'counselling',
      body:'A behavioral or mental pattern that causes significant distress or impairment of personal functioning. 1 in 7 people in Singapore has experienced a mood, anxiety or alcohol use disorder in their lifetime.',
      sourceurl: 'https://www.imh.com.sg/research/',
      source: 'IMH SMHS 2016'},
    {id:8, 
      slug:'bdd', 
      h1:'Body Dysmorphic Disorder', 
      number: '50', 
      location: 'people across the world',
      img: '1-in-50',
      help: 'counselling',
      body:"Body dysmorphic disorder (BDD), occasionally still called dysmorphophobia, is a mental disorder characterized by the obsessive idea that some aspect of one's own body part or appearance is severely flawed and therefore warrants exceptional measures to hide or fix it. In BDD's delusional variant, the flaw is imagined. If the flaw is actual, its importance is severely exaggerated. Either way, thoughts about it are pervasive and intrusive, and may occupy several hours a day, causing severe distress and impairing one’s otherwise normal activities.",
      sourceurl: 'https://en.wikipedia.org/wiki/Body_dysmorphic_disorder',
      source: 'Wikipedia'},
    {id:9, 
      slug:'bpd', 
      h1:'Borderline Personality Disorder', 
      number: '101', 
      location: 'people across the world',
      img: '1-in-101',
      help: 'counselling',
      body:"Borderline personality disorder (BPD), also known as emotionally unstable personality disorder (EUPD), is a mental illness characterized by a long-term pattern of unstable relationships, distorted sense of self, and strong emotional reactions. Those affected often engage in self-harm and other dangerous behavior. They may also struggle with a feeling of emptiness, fear of abandonment, and detachment from reality.",
      sourceurl: 'https://en.wikipedia.org/wiki/Borderline_personality_disorder',
      source: 'Wikipedia'},
    {id:10, 
      slug:'ptsd', 
      h1:'Post Traumatic Stress Disorder', 
      number: '19', 
      location: 'young people across the world',
      img: '1-in-19',
      help: 'counselling',
      body:'Post-traumatic Stress Disorder (PSTD) is a major psychological disorder that affects many people who survive major traumatic experiences. However, not everybody who suffers an accident or injury will suffer from PSTD. When the body suffers major trauma, the mind may become disturbed as well.',
      sourceurl: 'https://www.imh.com.sg/clinical/page.aspx?id=257',
      source: 'Institute of Mental Health'},
    {id:11, 
      slug:'psychosis', 
      h1:'Psychosis', 
      number: '50', 
      location: 'people in Singapore',
      img: '1-in-50',
      help: 'counselling',
      body:'Psychosis is a psychiatric syndrome that most commonly occurs in young adults. Around 1 in 50 people will experience a psychotic episode in their lifetime. A person who has a psychotic illness may have delusions, hallucinations, disordered thinking or abnormal behaviour. When someone becomes ill in this way, it is called a psychotic episode.',
      sourceurl: 'https://www.imh.com.sg/clinical/page.aspx?id=258',
      source: 'Institute of Mental Health'},
    {id:12, 
      slug:'eating-disorder', 
      h1:'Eating Disorder', 
      number: '200', 
      location: 'people across the world',
      img: '1-in-200',
      help: 'specialists',
      body:"An eating disorder is a mental disorder defined by abnormal eating habits that negatively affect a person's physical and/or mental health. They include binge eating disorder, where people eat a large amount in a short period of time; anorexia nervosa, where people eat very little due to a fear of gaining weight and thus have a low body weight; bulimia nervosa, where people eat a lot and then try to rid themselves of the food; pica, where people eat non-food items; rumination syndrome, where people regurgitate food; avoidant/restrictive food intake disorder (ARFID), where people have a reduced and selective food intake due to some psychological reasons (see below); and a group of other specified feeding or eating disorders.",
      sourceurl: 'https://en.wikipedia.org/wiki/Eating_disorder',
      source: 'Wikipedia'},
    {id:13, 
      slug:'schizophrenia', 
      h1:'Schizophrenia', 
      number: '335', 
      location: 'people across the world',
      img: '1-in-335',
      help: 'counselling',
      body:"Schizophrenia is not a disorder of 'split personality' but rather a disorder of fragmented mental processes. It is a major psychotic illness. Many suffering from schizophrenia would, usually, have suffered the symptoms of psychosis for at least six months before seeking help. Sufferers will have experienced a deterioration in interpersonal relationships and in daily functioning at school or at work. Although it can affect anyone at any age, its onset is usually in adolescence or young adulthood. Schizophrenia affects men and women equally.",
      sourceurl: 'https://www.imh.com.sg/clinical/page.aspx?id=259',
      source: 'Institute of Mental Health'},
    {id:14,
    body:'If you are experiencing mental health issues, we encourage you to reach out for help. In the 2016 Singapore Mental Health Study, more than 75% of people with mental disorders in their lifetime did not seek any professional help.'}
];