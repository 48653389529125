export const PostData = [
    {   id: 13,
        quote:"“JOY” I was at a house party celebrating Canada Day - when I met a woman named Joy. - I said: “It’s a joy to meet you Joy.” - She beamed at me, - then said: “I am manic-depressive.” - At once the conversation dried up. - Not knowing what to say...",
        issue:"MDD / Depression",
        issueslug: "depression all",
        link:"../story/joy"
    },
    {   id: 21,
        quote:"“A random note for fellow warriors in therapy” You are braver and stronger than you think - Because when I see you getting help, - I know that what you are actually doing is mustering insane amounts of strength and resilience to focus and sit with your demons week after week...",
        issue:"General",
        issueslug: "general all",
        link:"../story/a-random-note-for-fellow-warriors"
    },
    {   id: 33,
        quote:"As someone who had dysthymia for 2-3 years and overcame it, I just want to say that I'm proud of everyone who is fighting their own battles now. I don't know how hard it might be for you but please don't give up. We will see colours in the rainbow brighter than anyone else and smile the brightest at the most beautiful sunsets. Please don't think you are 'abnormal' because the idea of normal doesn't exist! It's your life and you can paint it how ever you wish. Sending love to everyone who has strangers in their heads, you are not alone <3",
        issue:"MDD / Depression",
        issueslug: "depression all"
    },
    {   id: 32,
        quote:"Hi Le Yong and Jess, my name is Ling. After watching your episodes on 'Strangers in my head' on CNA, I really feel motivated by your strength and resilience despite your challenges and what you both have gone through. I would like to widen my scope of friends and hope to know both Le Yong and Jess.",
        issue:"Schizophrenia",
        issueslug: "schizophrenia all"
    },
    {   id: 31,
        quote:"Bipolar disorder has completely derailed my life. I've completely wiped out my savings and had to quit my job due to numerous suicide attempts triggered by my mixed episodes due to work stress. I've learnt to take things an hour at a time, to prevent myself from getting overwhelmed. To anybody reading this, you are loved and you are not alone.",
        issue:"Bipolar",
        issueslug: "bipolar all"
    },
    {   id: 30,
        quote:"i'm so happy to see that there's awareness being raised in singapore. i am dealing with borderline personality disorder, depression and anxiety and i'm undergoing therapy after a failed suicide attempt. it's nice that there's more perspectives being shown and i hope someday reaching out for help will be accepted.",
        issue:"More than one issue",
        issueslug: "anxiety depression bpd all"
    },
    {   id: 29,
        quote:"I developed an eating disorder when I was 14 year old and the biggest problem was keeping quiet about it. As someone who has gone through around 3 years of ED + recovery and now, fully recovered, I would like to ask those going through the same situation to speak up and find help as soon as possible. Keeping it to yourself will only cause the situation to worsen. Hang in there, you got this!",
        issue:"Eating Disorder",
        issueslug: "eating-disorder all"
    },
    {   id: 28,
        quote:"I've been bullied since I was 6 years old. I was also bullied in primary and secondary school. As a result, I grew up with a really bad social anxiety. I couldn't even finish giving a 1 minute speech back in secondary school. I have no friends when i went to university because it was hard for me to strike up a conversation with people. There were also people in school who laughed at me just because of the way I look. Sometimes, when i walk in shopping centres, people would also look at me and laugh. This year during the pandemic, I've locked myself up at home for many days. I'll only go out once a week. The rest of the days. I try not to have any human contact as much as possible. I feel alone sometimes.",
        issue:"Anxiety / GAD",
        issueslug: "anxiety all"
    },
    {   id: 27,
        quote:"YOU are really inspiring and thank you for being vulnerable + so real in sharing snippets of your life. It's so easy to forget we are all humans too and our struggles in life shouldn't be so easily dismissed. Thanks le yong! :)",
        issue:"BDD / Body Dysmorphic Disorder",
        issueslug: "bdd all"
    },
    {   id: 26,
        quote:"Hello Le Yong, I just wanted to say thank you. You opened our eyes to this condition and you, as well as your family, are brave to be willing to share your story. Since you 'cannot' always see how you really look like, then you must trust us viewers that you are fine looking and don't need to check in the mirror! All the best to you and keep your chin up always. Regards,",
        issue:"BDD / Body Dysmorphic Disorder",
        issueslug: "bdd all",
        name:"–a viewer of Episode 1"
    },
    {   id: 24,
        quote:"There is no standard normal. Normal is subjective. There are 7 billion versions of normal on this planet.",
        issue:"General",
        issueslug: "general all",
        name:"–Matt Haigs"
    },
    {   id: 25,
        quote:"My personal experience with mental illness is seeing a close one dealing with addiction. It was like a dark cloud over our heads - a painful 6-year journey that still impacts me today. The person I know had changed completely with the addiction...",
        issue:"General",
        issueslug: "general all",
        link:"../story/my-personal-experience"
    },
    {   id: 19,
        quote:"When I was first diagnosed with depression, I had no idea that was what it was. I just didn't know anything about it.",
        issue:"MDD / Depression",
        issueslug: "depression all"
    },
    {   id: 0,
        quote:"I’ve lost some friends to depression, because they either felt that no one could understand them, or the stigma of mental illness prevents them from reaching out. This is why encouraging open discussion and acceptance of mental illnesses is so important. We should let our friends and family know that it’s ok to not be ok.",
        issue:"MDD / Depression",
        issueslug: "depression all",
        name:"–Sun Koh, Filmmaker"
    },
    {   id: 1,
        quote:"While walking the streets of NYC one day I came across a sign that said “depression is a flaw of chemistry, not character.” Those words itself were enough to pierce an arrow deep into my psyche. Remember, you’re not alone and can get through this. I’m talking from 18 years of personal experience.",
        issue:"MDD / Depression",
        issueslug: "depression all",
        name:"–Sid Reddy, Wildlife Photographer, India"
    },
    {   id: 2,
        quote:"“Healthy Healthcare Workers” TWe live in a world where healthcare workers are heroes. We clap, sing, and even salute them with a fly pass during the National Day Parade. But who cares for these heroes when they fall? Especially when they are robbed of their mental health, by the very work for which they are lauded...",
        issue:"General",
        issueslug: "general all",
        link:"../story/healthy-healthcare-workers"
    },
    {   id: 3,
        quote:"In every day life, we all experience ups and downs. And sometimes this down time continues without our knowing. We all have this moment and it is perfectly fine to feel in such a way. If we all can accept this, then, no one will need to suffer because they feel alone to experience this down moment.",
        issue:"General",
        issueslug: "general all",
        name:"–Haruka Hikita, Body, Mind, Soul Gardener"
    },
    {   id: 4,
        quote:"Move: Jump, run, squat, dance - a short burst of movements will release dopamine. When it hits me hard, I called a friend and laughed over silly things, she didn’t even need to realize what I’m going through.",
        issue:"General",
        issueslug: "general all",
        name:"–Jacqueline Tan, Singapore"
    },
    {   id: 18,
        quote:"I was first diagnosed with Major Depressive Disorder when I was 20, and in University. It took me about three or four years before I could bring myself to believe that something may not be quite right, and it was only through the persistence of a close friend that I finally saw a Counsellor...",
        issue:"BPD / Borderline Personality Disorder",
        issueslug: "bpd depression all",
        link:"../story/i-was-first-diagnosed"
    },
    {   id: 5,
        quote:"Grab your mask and go outside to enjoy nature. Take some pics of the beautiful changing fall and enjoy a hot cup of tea when you get back in.",
        issue:"General",
        issueslug: "general all",
        name:"–Nikola Spoljaric, Nature Lover, Croatia"
    },
    {   id: 6,
        quote:"Everyone has his own demons to wrestle. There are some who are given more ferocious, potentially fatal monsters to fight, of which we can only vaguely imagine what it’s like and hope we would never experience ourselves. If you have an easier monster, lesser demons; if you believe you fall into the spectrum of the “normal”, then show some support to those who were dealt the harder ones.",
        issue:"General",
        issueslug: "general all",
        name:"–Ang Sookoon, Artist"
    },
    {   id: 7,
        quote:"思い切って原因から遠ざかることは必要なことでした。自分ではどうすることもできないとき手を差し伸べてくれた人たちへの感謝は忘れません。今でも苦しいけれど、生きていこうと思えるようになりました。It was nessesary to change the dynamics of my life to stay away from the problem. I am still alive because of people who helped me when I needed it most.",
        issue:"MDD / Depression",
        issueslug: "depression all"
    },
    {   id: 8,
        quote:"A healthy mind equates to a healthy body. The importance of eating right, exercising, keeping an active lifestyle has always been directed towards ensuring that we have a healthy body free from ailments such as diabetes, high blood pressure, cardio-related diseases, etc. However, these habits...",
        issue:"General",
        issueslug: "general all",
        link:"../story/a-healthy-mind"
    },
    {   id: 9,
        quote:"“THEY ARE WATCHING ME” - “There, they are watching me from there, do you see them?” Whispered my father, his body crouched low against the lower windows of our balcony. Beside him, my neighbour was attempting to nod sympathetically, though he was obviously confused...",
        issue:"Schizophrenia",
        issueslug: "schizophrenia all",
        link:"../story/they-are-watching-me"
    },
    {   id: 10,
        quote:"In 1983, my father, Goh Poh Seng: doctor, poet, novelist, playwright, nightclub owner, businessman and entrepreneur was the sponsor and promoter of David Bowie’s Serious Moonlight Tour in Singapore. My father, widely acknowledged in hindsight as a pioneer of the arts...",
        issue:"Depression",
        issueslug: "depression all",
        link:"../story/in-1983-my-father"
    },
    {   id: 12,
        quote:"Mental health problems don’t define who we are.",
        issue:"Bipolar",
        issueslug: "bipolar all"
    },
    {   id: 11,
        quote:"“Dying to be Alive: an unconventional path to finding happiness” Tragedy strikes - In 2015, I received a text from an ex-colleague: “Recalling all CARE (Caring Action in Response to Emergencies) trained officers. Please standby to offer support.” At that moment, I was puzzled – what support did they need from us?...",
        issue:"General",
        issueslug: "general all",
        link:"../story/dying-to-be-alive"
    },
    {   id: 14,
        quote:"“An Average Morning” This post is not going to be pretty.I crawled back into bed when I started to feel overwhelming feelings of unworthiness from underachievement. From guilt, and from shame. Crawling back into bed and hiding under the blanket is like running back...",
        issue:"BPD / Borderline Personality Disorder",
        issueslug: "bpd all",
        link:"../story/an-average-morning"
    },
    {   id: 15,
        quote:"全ての人ではないけど、話すことで相手が自分の苦しみを理解しようとしてくれたり、辛さの差はあっても同じような思いを相手も抱えていたと気づくことは少なくありません。When I am brave enought to talk about my depression, although it is not every time, I more often find people willing to understand, or they also share their own struggle.",
        issue:"MDD / Depression",
        issueslug: "depression all"
    },
    {   id: 16,
        quote:"“Social Anxiety” Being socially anxious is exhausting, as is being anxious in general. Social anxiety, however, is particularly challenging in my opinion, because we all live in communities and communities are essentially based upon social relationships and interactions...",
        issue:"Anxiety / GAD",
        issueslug: "anxiety all",
        link:"../story/social-anxiety"
    },
    {   id: 17,
        quote:"“Intense highs/Crushing lows” How painful it is to be so overwhelmed - That you feel crippled and paralysed, unable to make sense of what is happening to you and why. - A veritable tornado of nothing and everything, storming your entirety...",
        issue:"General",
        issueslug: "general all",
        link:"../story/intense-highs-crushing-lows"
    },
    {   id: 20,
        quote:"“Superhuman” In your wounded, raw and vulnerable state, you give in to their hopeful, almost pleading coaxing. You hear how much they want you to open up and share something about what you are going through with them - you have people who love you and want to help. It is beautiful, you are blessed...",
        issue:"General",
        issueslug: "general all",
        link:"../story/superhuman"
    },
    {   id: 22,
        quote:"It's been 11 months, a month more to celebrating a year relationship with my girlfriend struggling with a borderline personality disorder. Most people tend to make conclusions of the difficulties in my relationship and very often the general crowd are quick to feel pity for a person like me being in a challenging relationship...",
        issue:"General",
        issueslug: "general all",
        link:"../story/its-been-11-months"
    },
    {   id: 23,
        quote:"A couple of decades ago, a good friend of mine jumped to her death. She was only 26, but had been suffering from anorexia and depression for a couple of years. Her family and many of her friends including me were gutted. We were filled with guilt and asked ourselves many questions: Could we have done more?...",
        issue:"General",
        issueslug: "general all",
        link:"../story/a-couple-of-decades-ago"
    }
];